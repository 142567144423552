/* reset css   */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: SVN-Gotham-light;
  color: white;
  background-color: #1288d5;
}
input,
button {
  font-family: SVN-Gotham-light;
}
h1,
.bold {
  font-family: SVN-Gotham;
}

@font-face {
  font-family: "SVN-Gotham";
  src: url("./assets/fonts/SVN-GothamRegular.otf");
}
@font-face {
  font-family: "SVN-Gotham-light";
  src: url("./assets/fonts/SVN-Gotham Light.otf");
}
.container {
  padding: 0 20px;
  min-height: 50vh;
}

.login-page {
  margin-top: 0px;
}

.login-page .login-top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 28px;
}

.login-page .login-top_title {
  font-size: 32px;
  line-height: 26px;

  text-align: center;
  text-transform: uppercase;

  color: #ffe900;
  margin-bottom: 20px;
}

.login-page .login-top_decription {
  height: 27.44px;

  font-family: "SVN-Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  text-align: center;

  color: #ffffff;
}
.login-page .login-top_description {
  /* height: 27.44px; */

  font-family: "SVN-Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: left;

  color: #1a6a36;
}
.login-page .user-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}
.login-page .user-input-number {
  padding: 16px 20px;
  gap: 10px;
  background: #ffffff;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
}

.login-page .user-input-number::placeholder {
  font-family: "SVN-Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  /* Gray 4 */

  color: #bdbdbd;
}
.login-page .policy-check {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.login-page form p {
  display: flex;
  justify-content: center;
}

.login-page .policy-check input {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-left: 5px;
  line-height: 20px;
  border: 1px solid #89939e;
  border-radius: 4px;
}

.login-page .policy-decription {
  width: 100%;
  height: 38px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  color: #ffe900;
}
.login-page .policy-decription > a {
  text-decoration: none;
  color: #ffe900;
}
.login-page .login-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 28px;
}
.login-page .login-button button,
.login-page .login-button .rule,
.button-primary {
  width: 100%;

  padding: 15px;
  border-radius: 12px;
  text-transform: uppercase;
}
.login-page .login-button .disable {
  background: #8d8d8d;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: white;
  border: none;
}
.button-primary {
  margin-top: 15px;
}
.login-page .login-button .enable,
.button-primary {
  background: #1a6a36;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  border: 2px solid white;

  /* P&G tet/Red 2 */

  color: #ffffff;
}

.login-page .login-button .rule {
  background: #ae110c;
  text-decoration: unset;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.confirm-page.wrapper {
  padding: 0 43.5px;
  text-align: center;
  margin-top: 0px;
}

.confirm-page .confirm-title {
  margin-bottom: 60px;
}
.confirm-page .confirm-title > h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #ffe900;
  margin-bottom: 10px;
}
.confirm-page .confirm-title p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #1a6a36;
}
.confirm-page .confirm-input > h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: -20px;
  color: #1a6a36;
}
.confirm-page .confirm-input .user-otp {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
}
.confirm-input .otp-element {
  gap: 6px;
}
.confirm-input .otp-element > input {
  width: 52px !important;
  height: 52px !important;
  background: #ffffff;
  border-radius: 8px;
  border: none;
  margin-bottom: 24px;
}
.confirm-page .otp-reRequest {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  color: #1a6a36;
}

.confirm-page .otp-reRequest span > a {
  text-decoration: none;
  margin-left: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #1a6a36;
}

.confirm-page .confirm-button {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.annouce-prize {
  margin-top: 15px;
  text-align: center;
}
.annouce-prize .announce-title {
  margin-bottom: 85px;
}
.annouce-prize .announce-title > h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;
  margin-top: -20px;
  color: #ffe900;
  margin-bottom: 12px;
}

.annouce-prize .prize {
  margin-bottom: 12px;
}

.annouce-prize .prize img {
  max-width: 180px;
  max-height: 150px;
  margin-top: -60px;
}

.annouce-prize .announce-title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: -10px;
  color: #ffffff;
}

.annouce-prize .prize h5 {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* or 183% */

  text-align: center;

  color: #ffffff;
}

.annouce-prize .prize .prize-name {
  font-size: 16px;
}

.annouce-prize .prize .prize-worth {
  color: #efdb8e;
  font-size: 20px;
  font-weight: 900;
  padding-left: 5px;
}
.annouce-prize .prize-decription {
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  font-family: SVN-Gotham-light;
  color: #ffffff;
}
.annouce-prize .button-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.annouce-prize .button-nav .get-prize {
  padding: 12px 0;
  width: 150px;
  gap: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;

  background: #4cbfec;
  border: 4px solid #73d8ff;
  border-radius: 16px;
  margin-right: 15px;
}

.annouce-prize .button-nav .continue {
  padding: 10px 0;
  width: 200px;
  gap: 10px;
  background: #efdb8e;
  border: 4px solid #ffe584;
  border-radius: 16px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #1a6a36;
}
.annouce-prize .button-nav .spin-remain {
  background: #ffe584;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #000000;
  padding: 8px;
  display: inline-block;
}
.mainbox {
  margin-top: 20px;
}
.end-spin {
  text-align: center;
}
.end-spin .end-title > h1 {
  font-weight: 900;
  font-size: 28px;
  line-height: 39px;
  text-transform: uppercase;

  color: #f6de8b;
}
.end-spin .end-img {
  margin-top: 30px;
}

.end-spin .end-decription {
  margin-top: 10x;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.end-spin .end-nav .home-btn {
  margin-top: 27px;
  padding: 16px 32px;

  background: #efdb8e;
  border: 4px solid #eed163;
  border-radius: 16px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;

  color: #b12125;
}

.header-nav .nav-left {
  position: absolute;
  top: 34px;
  left: 34px;
}

.footer-nav {
  position: relative;
}

.hide-prize {
  display: none;
}
.show-prize {
  display: block;
}
.hide-wheel {
  display: none;
}
.get-prize-container {
  padding-left: 15px;
  padding-right: 15px;
}
.get-prize {
  background-image: url("./assets/getPrize_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  text-align: center;
  max-width: 420px;
  max-height: 624px;
  margin-right: 15px;
  margin: auto;
}
.get-prize .prize-content > h1 {
  font-weight: 900;
  font-size: 28px;
  line-height: 39px;

  text-transform: uppercase;

  color: #006eb9;
  margin-bottom: 12px;
}
.get-prize {
  padding-top: 100px;
  padding-bottom: 30px;
}
.prize-content.container {
  padding: 0 40px;
}
a.get-prize {
  margin-left: 0;
}

.get-prize .prize-content p {
  font-family: SVN-Gotham-light;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #333333;
  margin-bottom: 24px;
}
.get-prize .gift-open-amount,
.gift-open-name,
.gift-open-value {
  font-family: SVN-Gotham;
  color: #333333;
}
.get-prize-decription {
  font-family: SVN-Gotham-light;
  color: #333333;
  margin-top: 13px;
}
.gift-open-total {
  font-family: SVN-Gotham;
  color: #b12125;
  font-size: 20px;
}
.get-prize-btn {
  display: flex;
  justify-content: center;
  margin-top: 19px;
}
.get-prize-btn > button {
  font-family: SVN-Gotham;
  background: #1a6a36;
  border: 4px solid #fff69a;
  border-radius: 16px;
  padding: 16px 32px;
  font-weight: 700;
  font-size: 16px;

  text-align: center;

  color: #ffffff;
}

.all-gift {
  height: 250px;

  overflow: scroll;
}
.prize-img {
  margin-top: 15px;
}
.prize-img > img {
  width: 150px;
}
.ReactModalPortal p {
  color: black;
}

.ReactModalPortal a {
  text-align: center;
  word-break: break-all;
}

.noaccess-content {
  align-items: center;
  border: 3px solid #f7de8c;
  text-align: center;
  display: flex;
  margin-top: 40vh;
  margin-left: 15px;
  margin-right: 15px;
  padding: 15px;
}
.noaccess-content p {
  width: 100%;
}
.float-buttons {
  position: fixed;
  bottom: 15px;
  right: 15px;
}
